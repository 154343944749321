export enum PharmacyFields {
  id = 'id',
  arm = 'arm',
  eng = 'eng',
  rus = 'rus',
  documentId = 'documentId',
  strength = 'strength',
  strengthUnit = 'strengthUnit',
  strengthUnitCode = 'strengthUnitCode',
  name = 'name',
  description = 'description',
  quantity = 'quantity',
  mainIngredient = 'mainIngredient',
  type = 'type',
  validationDate = 'validationDate',
  validFrom = 'validFrom',
  dosageForm = 'dosageForm',
  brands = 'brands',
  code = 'code',
  address = 'address',
  latitude = 'latitude',
  longitude = 'longitude',
  lng = 'lng',
  lat = 'lat',
  pharmacyId = 'pharmacyId',
  label = 'label',
  branch = 'branch',
  pharmacy = 'pharmacy',
  workingHours = 'workingHours',
  medicaments = 'medicaments',
  producer = 'producer',
  price = 'price',
  success = 'success',
  message = 'message',
  bookingPaymentUrl = 'bookingPaymentUrl',
  branchCode = 'branchCode',
  key = 'key',
  value = 'value',
  paymentDetails = 'paymentDetails',
  requestId = 'requestId',
  status = 'status',
  date = 'date',
  approvalCode = 'approvalCode',
}
